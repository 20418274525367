import type {
  Price as BapiPrice,
  Product,
  Variant,
  LowestPriorPrice,
} from '@scayle/storefront-nuxt'
import { useGlassesLenses } from './pdp/useGlassesLenses'
import { filterLensQualityIndexItems } from '~/utils/rx'

const getFramePrice = (product: Product, variant?: Variant) => {
  const lowestPrice = product?.priceRange?.min || getLowestPrice(product)

  return variant ? (variant?.price ?? lowestPrice) : lowestPrice
}

export const useProductPrice = () => {
  const { $i18n, $currentShop, $featureToggle } = useNuxtApp()
  const { isRxConfiguratorEnabled } = $featureToggle
  const glassesLensesPromise = useGlassesLenses()
  const { lensesVariants: lensQualityIndexesVariants, lensesProducts } =
    glassesLensesPromise

  const getLensQualityIndexesVariants = (product: Product) =>
    filterLensQualityIndexItems<Variant>(
      lensQualityIndexesVariants,
      product,
      $currentShop,
    )

  const getLensQualityIndexesProducts = (product: Product) =>
    filterLensQualityIndexItems<Product>(lensesProducts, product, $currentShop)

  const getCombinedProductPrice = (
    product: Product,
    variant?: Variant,
  ): BapiPrice => {
    const framePrice = getFramePrice(product, variant)

    if (!isCorrectionalGlasses(product)) {
      // priceRange will include the lowest price available excluding variants out of stock
      return framePrice
    }

    const variants = getLensQualityIndexesVariants(product)

    return variants?.length && isRxConfiguratorEnabled(product)
      ? combinePricesWithCheapestLens(framePrice, variants)
      : framePrice
  }

  const getCombinedLowestPriorPrice = (
    product: Product | null,
    variant?: Variant,
  ): LowestPriorPrice => {
    if (!product) {
      return { withTax: null, relativeDifferenceToPrice: null }
    }
    const framePrice = getFramePrice(product, variant)

    const framePriorPrice = product?.lowestPriorPrice?.withTax
      ? product?.lowestPriorPrice
      : calculateFallbackPriorPrice(framePrice)

    const lensVariants = getLensQualityIndexesVariants(product)
    const lensProducts = getLensQualityIndexesProducts(product)

    // Calculate lowestPriorPrice for RX products
    if (lensVariants?.length && isRxConfiguratorEnabled(product)) {
      const glass = getCheapestGlassProduct(lensProducts)

      const glassPrice = glass?.priceRange?.min || getLowestPrice(glass)

      const glassPriorPrice = glass?.lowestPriorPrice?.withTax
        ? glass?.lowestPriorPrice
        : calculateFallbackPriorPrice(glassPrice)

      const withTax =
        (framePriorPrice.withTax || 0) + (glassPriorPrice.withTax || 0)

      const relativeDifferenceToPrice =
        1 - withTax / (framePrice.withTax + glassPrice.withTax)

      return {
        withTax,
        relativeDifferenceToPrice: Number.parseFloat(
          relativeDifferenceToPrice.toFixed(2),
        ),
      }
    }

    return framePriorPrice
  }

  const getFrame = (product: Product, variant?: Variant) => {
    const variantValid = variant?.price
    const productValid = product?.variants?.length

    const framePrice =
      variantValid || productValid ? getFramePrice(product, variant) : undefined

    if (!product || !framePrice) {
      return { product: null, price: null }
    }
    return {
      product,
      price: framePrice,
    }
  }

  const getCheapestLensForFrame = (product: Product) => {
    if (!product || !isCorrectionalGlasses(product)) {
      return { product: null, price: null }
    }

    const cheapestLensVariant = getCheapestGlassVariant(
      getLensQualityIndexesVariants(product),
    )

    const cheapestLensProduct = getCheapestGlassProduct(
      getLensQualityIndexesProducts(product),
    )
    return {
      product: cheapestLensProduct,
      price: cheapestLensVariant?.price,
      variant: cheapestLensVariant,
    }
  }

  return extendPromise(
    glassesLensesPromise.then(() => ({})),
    {
      getCombinedProductPrice,
      getFrame,
      getCheapestLensForFrame,
      getCombinedLowestPriorPrice,
    },
  )
}
